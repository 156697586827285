import BadgeIcon from '@mui/icons-material/Badge';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
// import AnnouncementIcon from '@mui/icons-material/Announcement';
// import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import MapIcon from '@mui/icons-material/Map';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StoreIcon from '@mui/icons-material/Store';
import TextsmsIcon from '@mui/icons-material/Textsms';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

const ITEM_DATA = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon htmlColor='#fff' />,
    link: '/',
    permissions: {
      status: ['super-admin', 'business-owner', 'permanent-employee'],
      titles: ['manager', 'driver', 'washer'],
    },
  },
  {
    title: 'Messaging',
    icon: <TextsmsIcon htmlColor='#fff' />,
    link: '/messaging',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager', 'driver', 'washer'],
    },
  },
  // {
  //   title: 'QR Code',
  //   icon: <QrCode2Icon htmlColor='#fff' />,
  //   link: '/qr-code',
  //   permissions: {
  //     status: ['super-admin', 'business-owner'],
  //     titles: ['manager', 'driver'],
  //   },
  // },
  {
    title: 'Laundry Pickup',
    icon: <UnarchiveIcon htmlColor='#fff' />,
    link: '/laundry-pickup',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager', 'driver'],
    },
  },
  {
    title: 'Lease Inventory',
    icon: <InventoryIcon htmlColor='#fff' />,
    link: '/inventory',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager', 'driver'],
    },
  },
  {
    title: 'Store',
    icon: <StoreIcon htmlColor='#fff' />,
    link: '/store',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager'],
    },
  },
  {
    title: 'Machines',
    icon: <LocalLaundryServiceIcon htmlColor='#fff' />,
    link: '/machines',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager'],
    },
  },
  {
    title: 'Employees',
    icon: <BadgeIcon htmlColor='#fff' />,
    link: '/employees',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager'],
    },
  },
  {
    title: 'Bin History',
    icon: <WorkHistoryIcon htmlColor='#fff' />,
    link: '/bin-history',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager', 'driver'],
    },
  },
  {
    title: 'Customers',
    icon: <PeopleIcon htmlColor='#fff' />,
    link: '/users',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager'],
    },
  },
  {
    title: 'Receipts',
    icon: <ReceiptIcon htmlColor='#fff' />,
    link: '/receipts',
    permissions: {
      status: ['super-admin'],
    },
  },
  {
    title: 'Routes',
    icon: <MapIcon htmlColor='#fff' />,
    link: '/routes',
    permissions: {
      status: ['super-admin', 'business-owner'],
      titles: ['manager', 'driver'],
    },
  },
  // {
  //   title: 'Issues',
  //   icon: <PriorityHighIcon htmlColor='#fff' />,
  //   link: '/issues',
  //   permissions: {
  //     status: ['super-admin', 'business-owner', 'permanent-employee'],
  //     titles: ['manager', 'driver', 'washer'],
  //   },
  // },
  // {
  //   title: 'Meetings',
  //   icon: <AnnouncementIcon htmlColor='#fff' />,
  //   link: '/meetings',
  //   permissions: {
  //     status: ['super-admin', 'business-owner', 'permanent-employee'],
  //     titles: ['manager', 'driver', 'washer'],
  //   },
  // },
  // {
  //   title: '1-on-1s',
  //   icon: <QuestionAnswerIcon htmlColor='#fff' />,
  //   link: '/one-on-one',
  //   permissions: {
  //     status: ['super-admin', 'business-owner', 'permanent-employee'],
  //     titles: ['manager', 'driver', 'washer'],
  //   },
  // },
];

export default ITEM_DATA;
