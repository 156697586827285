import { ChangeEvent, useState } from 'react';
import { ErrorType } from '../../../types/types';
import { resetErrorMsg } from '../../create-qr-code/types';
import { Stack, TextField } from '@mui/material';
import { PickupBin } from '../types';

type Props = {
  binData: PickupBin;
  updateParentPickupBin: (binData: PickupBin) => void;
};
/**
 * This component is responsible for handling the form data
 * and the errors. It will then pass the data to the parent component via
 * the function passed as the Props param.
 */
const PickupBinForm = ({ binData, updateParentPickupBin }: Props) => {
  // states for the top inputs
  const [binNumber, setBinNumber] = useState<string>(binData.binNumber);
  const [binWeight, setBinWeight] = useState<string>(binData.weight);
  const [notes, setNotes] = useState<string>(binData.notes ? binData.notes : '');
  // errors
  const [binNumberError, setBinNumberError] = useState<ErrorType>(resetErrorMsg);
  const [binWeightError, setBinWeightError] = useState<ErrorType>(resetErrorMsg);
  /* HANDLING INPUTS */
  const handleBinNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    // returns passed value
    const validInput = isAValidNumberInput(value);
    const _binData: PickupBin = {
      binNumber,
      weight: binWeight,
      notes: notes,
      deliveryNotes: binData.deliveryNotes ? binData.deliveryNotes : 'N/A',
    };
    if (validInput !== null) {
      setBinNumber(`${validInput}`);
      if (binNumberError.isError) {
        setBinNumberError(resetErrorMsg);
      }
      _binData.binNumber = `${validInput}`;
      updateParentPickupBin(_binData);
    } else {
      _binData.binNumber = '';
      setBinNumber('');
      setBinNumberError({ isError: true, message: 'Cannot be empty. Must be greater than 0.' });
      updateParentPickupBin(_binData);
    }
  };
  // handling the bin weight input
  const handleBinWeightChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const _binData: PickupBin = {
      binNumber,
      weight: binWeight,
      notes: notes,
      deliveryNotes: binData.deliveryNotes ? binData.deliveryNotes : 'N/A',
    };
    const trimmedValue = value.trim();
    if (trimmedValue === '') {
      _binData.weight = '';
      setBinWeight(trimmedValue);
      setBinWeightError({ isError: true, message: 'Cannot be empty' });
    } else {
      // replace any +/- symbols with an empty space
      const replacedValue = trimmedValue.replaceAll('+', '').replaceAll('-', '');
      // we need to check to see if the last value of the string is a '.'.
      // if so then we need to let the user finish adding a numbe after
      if (replacedValue.endsWith('.')) {
        _binData.weight = `${replacedValue}`;
        setBinWeight(`${replacedValue}`);
      } else {
        // Notes for parseFloat: will ignore any non digits. ignores anything after a non digit.
        // ex. 10.55hb55 -> 10.55 / +10.551 -> 10.551 / -12.25 -> 12.25 / cds12.55 -> NaN
        // check if there is a '.' then wee need to fix to one decimal
        if (replacedValue.includes('.')) {
          const parsedFloatValue = parseFloat(replacedValue).toFixed(1);
          _binData.weight = `${parsedFloatValue}`;
          setBinWeight(`${parsedFloatValue}`);
        } else {
          // if not then we can leave it as an int
          const parsedFloatValue = parseFloat(replacedValue);
          _binData.weight = `${parsedFloatValue}`;
          setBinWeight(`${parsedFloatValue}`);
        }
      }
      if (binWeightError.isError) {
        setBinWeightError(resetErrorMsg);
      }
    }
    updateParentPickupBin(_binData);
  };
  // helper method to check if string input value is a valid number
  const isAValidNumberInput = (value: string) => {
    let isValid = null;
    if (value === '' || value === '+' || value === '-') {
      isValid = '';
    } else {
      // check to see if the string input is a number
      const binNumberInt = parseFloat(value);
      if (!isNaN(binNumberInt) && binNumberInt > -1) {
        isValid = binNumberInt;
      }
    }
    return isValid;
  };
  // handling the notes
  const handleNotesChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };

  return (
    <Stack>
      <TextField
        label='Bin #'
        name='binNumber'
        margin='dense'
        type='string'
        value={binNumber}
        error={binNumberError.isError}
        helperText={binNumberError.message}
        onChange={(e) => handleBinNumberChange(e)}
      />
      <TextField
        label='Bin Weight'
        name='binWeight'
        margin='dense'
        type='string'
        value={binWeight}
        error={binWeightError.isError}
        helperText={binWeightError.message}
        onChange={(e) => handleBinWeightChange(e)}
      />
      <TextField
        label='Notes'
        name='notes'
        margin='dense'
        multiline
        value={notes}
        onChange={(e) => handleNotesChange(e)}
      />
    </Stack>
  );
};

export default PickupBinForm;
