import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { createSingleNoShowPickupHistory } from '../../../api/laundry-pickup/pickup-history';
import { Employee, User } from '../../../types/types';
import ConfirmDialog from '../../common/ConfirmDialog';
import LoadingSpinner from '../../common/LoadingSpinner';
import { NoShowPickupType, PickupBin, PickupHistory } from '../types';
import PickupBinForm from './PickupBinForm';
import PickupBinQrCodes from './PickupBinQrCodes';
import PickupBinReview from './PickupBinReview';

const MAX_BIN_WEIGHT = 28;
const OVERWEIGHT_BIN_MESSAGE = 'Bin weight exceeds 28 lbs. This will be considered a split bag.';

type Props = {
  customer: User;
  employee: Employee;
  close: () => void;
};

const PickupBinDialog = ({ customer, employee, close }: Props) => {
  const [mainBin, setMainBin] = useState<PickupBin>({
    binNumber: '',
    weight: '0',
    notes: customer.notes ? customer.notes : '',
    deliveryNotes: customer.deliveryNotes ? customer.deliveryNotes : '',
  });
  const [isOverWeight, setIsOverWeight] = useState<boolean>(false);
  const [isExpressService, setIsExpressService] = useState<boolean>(false);
  // const [hasConfirmedSplitWeight, setHasConfirmedSplitWeight] = useState<boolean>(false);
  const [finalPickups, setFinalPickups] = useState<PickupHistory[]>([]);
  // 0 -> initial binForm | 1 -> review step | 2 -> generate QR codes
  const [currentStep, setCurrentStep] = useState<number>(0);
  // this is used for the disabled attribute of a MUI button.
  // false == user can click button
  // true == user cannot click the button
  const [canAdvanceToNextStep, setCanAdvanceToNextStep] = useState<boolean>(false);
  // confirmation dialog for 'no show'
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  /**
   * This function is passed to the child component PickupBinForm.
   * The child component will call it after every input change to update the parent
   * bin list
   */
  const updateMainBin = (binData: PickupBin) => {
    // we can assume that by the time we get here the value is a string
    // that can be converted to a parseInt
    // working with the main bin form
    // if a service is an express service then there is no overweight
    if (parseInt(binData.weight) > MAX_BIN_WEIGHT && !isExpressService) {
      setIsOverWeight(true);
    } else {
      // double check to see if it is currently false
      if (isOverWeight) {
        setIsOverWeight(false);
      }
    }
    setMainBin(binData);
    checkIfFormIsValid(binData);
    return;
  };

  // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setHasConfirmedSplitWeight(event.target.checked);
  // };

  // this function is called when the 'Review', 'Generate' button is clicked
  const handleButtonClick = () => {
    if (currentStep === 0) {
      setCurrentStep(1);
      return;
    }
    if (currentStep === 1) {
      // going into the generate we need to have a confirmation
      setConfirmationDialogInView(true);
      return;
    }
  };

  // This function will calculate if user is ready for the next step
  const checkIfFormIsValid = (binData: PickupBin) => {
    let isGoodToGo = true;
    // check that the bin number has a value
    const intBinNumber = parseInt(binData.binNumber);
    if (isNaN(intBinNumber)) {
      isGoodToGo = false;
    } else {
      if (intBinNumber < 0) {
        isGoodToGo = false;
      }
    }
    // check that bin weight is greater than zero
    const intBinWeight = parseFloat(binData.weight);
    if (isNaN(intBinWeight)) {
      isGoodToGo = false;
    } else {
      if (intBinWeight < 1) {
        isGoodToGo = false;
      }
    }
    setCanAdvanceToNextStep(isGoodToGo);
    return;
  };

  // this function handles the process of a 'no show' customer.
  // by making having the confirmation dialog appear
  const handleNoShowClickConfirmation = async (res: boolean) => {
    // no show confirmed
    if (res) {
      setCurrentStep(-1);
      setConfirmationDialogInView(false);
      const noShowPickUp: NoShowPickupType = {
        customer: {
          address: customer.address,
          aptNum: customer.apt ? customer.apt : null,
          city: customer.city,
          fullName: customer.fullName,
          phoneNumber: customer.phoneNumber,
          postalCode: customer.postalcode,
          state: customer.state,
          uid: customer.docID,
        },
        createdAt: Timestamp.now(),
        createdBy: {
          name: `${employee.firstName} ${employee.lastName}`,
          uid: employee.uid ? employee.uid : 'missingUID',
        },
        isDeleted: false,
        noShow: true,
        storeUID: employee.storeID,
      };
      const { success } = await createSingleNoShowPickupHistory(noShowPickUp);
      if (success) {
        toast.success(`No Show marked for ${customer.fullName} `);
        close();
      }
    } else {
      toast.error('Failed to create a No Show. Please try again.');
      setConfirmationDialogInView(false);
    }
  };
  // this function handles the confirmation to go into the QR generation
  const handleQRCodeGenerationConfirmation = async (res: boolean) => {
    if (res) {
      const _finalPickups = [];
      // we need to generate the pickupHistoryObjects to then pass to the component
      // PickupBinQRCodes
      // we will create a single object
      const pickupBinObject: PickupHistory = {
        bagNumber: mainBin.binNumber,
        createdAt: Timestamp.now(),
        createdBy: {
          name: `${employee.firstName} ${employee.lastName}`,
          uid: employee.uid ? employee.uid : 'noUID',
        },
        customer: {
          address: customer.address,
          aptNum: customer.apt ? customer.apt : '',
          city: customer.city,
          fullName: customer.fullName,
          phoneNumber: customer.phoneNumber,
          postalCode: customer.postalcode,
          state: customer.state,
          uid: customer.docID,
        },
        hasDamagedItems: false,
        isDeleted: false,
        isExpressService: isExpressService,
        isSplitBin: isOverWeight,
        noShow: false,
        notes: customer.notes ? customer.notes : '',
        deliveryNotes: customer.deliveryNotes ? customer.deliveryNotes : '',
        progressStatus: {
          pickedUp: {
            employee: {
              name: `${employee.firstName} ${employee.lastName}`,
              uid: employee.uid ? employee.uid : 'noUID',
            },
            timestamp: Timestamp.now(),
          },
          washed: null,
          dried: null,
          foldRack: null,
          delivered: null,
        },
        storeUID: employee.storeID,
        updatedAt: Timestamp.now(),
        weight: parseFloat(mainBin.weight),
      };
      // copy the object and change the binNumber for both to include letters
      if (isOverWeight) {
        const _pickupBinObjectA = { ...pickupBinObject };
        _pickupBinObjectA.bagNumber = `${pickupBinObject.bagNumber}-A`;
        const _pickupBinObjectB = { ...pickupBinObject };
        _pickupBinObjectB.bagNumber = `${pickupBinObject.bagNumber}-B`;
        _finalPickups.push(_pickupBinObjectA);
        _finalPickups.push(_pickupBinObjectB);
      } else {
        _finalPickups.push(pickupBinObject);
      }
      setFinalPickups(_finalPickups);
      // we will then check to see if the bin needs to be split into two
      setCurrentStep(2);
    }
    setConfirmationDialogInView(false);
  };
  // this function is called when the user clicks the 'cancel'/'close' button
  // if the steps < 2 it will close the form
  // if step === 2 they are at the QR code print screen and need to confirm that they want
  // to close the entire form
  const handleCancelCloseButtonClick = () => {
    if (currentStep !== 2) {
      close();
    } else {
      setConfirmationDialogInView(true);
    }
  };

  const handleQRCodeCloseConfirmation = async (res: boolean) => {
    if (res) {
      close();
    }
    setConfirmationDialogInView(false);
  };

  useEffect(() => {
    updateMainBin(mainBin);
  }, [isExpressService]);
  // array of the confirmation options
  const confirmationData = [
    {
      message: `You are confirming a NO SHOW for ${customer.fullName}. They will be charged a NO SHOW fee.`,
      function: handleNoShowClickConfirmation,
    },
    {
      message: 'Data has been reviewed. QR Codes will be generated.',
      function: handleQRCodeGenerationConfirmation,
    },
    {
      message: 'Please confirm that all tags have been printed.',
      function: handleQRCodeCloseConfirmation,
    },
  ];
  return (
    <>
      <Dialog open={true} onClose={close} fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={9}>
              <Stack>
                {currentStep !== -1 ? (
                  <>
                    <Typography variant={'h4'} textAlign={'left'}>
                      {customer.fullName}
                    </Typography>
                    <Typography align='left'>
                      {customer.address} {customer.apt} {customer.city}, {customer.state}{' '}
                      {customer.postalcode}
                    </Typography>
                  </>
                ) : (
                  <Typography textAlign={'left'} fontWeight={'bold'}>
                    {customer.fullName}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={3}>
              {currentStep !== -1 && currentStep === 0 ? (
                <Stack flexDirection={'column'}>
                  <Typography fontSize={12}>Express</Typography>
                  <Switch
                    onChange={() => setIsExpressService(!isExpressService)}
                    inputProps={{ 'aria-label': 'Express Service' }}
                    checked={isExpressService}
                  />
                </Stack>
              ) : isExpressService ? (
                <Typography color={'primary'}>Express Service</Typography>
              ) : null}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* No Show Pick Up Process */}
          {currentStep === -1 && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Typography>Creating No Show</Typography>
              <LoadingSpinner />
            </Box>
          )}
          {/* Initial Pick up bin Form */}
          {currentStep === 0 && (
            <Box>
              <PickupBinForm binData={mainBin} updateParentPickupBin={updateMainBin} />
              {isOverWeight ? (
                // <Stack>
                //   <FormGroup>
                //     <FormControlLabel
                //       control={
                //         <Checkbox
                //           checked={hasConfirmedSplitWeight}
                //           onChange={handleCheckboxChange}
                //         />
                //       }
                //       label='Split bin'
                //     />
                //   </FormGroup>
                //   <Typography color={'orange'} textAlign={'left'} pt={1} pb={1}>
                //     {OVERWEIGHT_BIN_MESSAGE}
                //   </Typography>
                // </Stack>
                <Typography color={'orange'} textAlign={'left'} pt={1} pb={1}>
                  {OVERWEIGHT_BIN_MESSAGE}
                </Typography>
              ) : null}
            </Box>
          )}
          {/* The review step */}
          {currentStep === 1 && (
            <Box>
              <Stack direction={'row'} spacing={2}>
                <ButtonBase onClick={() => setCurrentStep(0)}>
                  <Stack direction={'row'} color={'orange'}>
                    <ArrowBack fontSize='small' />
                    <Typography fontWeight={'bold'}>Edit</Typography>
                  </Stack>
                </ButtonBase>
                <Typography textAlign={'center'} fontWeight={'bold'}>
                  Please review details
                </Typography>
              </Stack>
              <PickupBinReview binData={mainBin} isSplit={isOverWeight} />
            </Box>
          )}
          {currentStep === 2 && (
            <Box>
              <PickupBinQrCodes
                pickupBins={finalPickups}
                washType={customer.washType ? customer.washType : 'N/A'}
              />
            </Box>
          )}
        </DialogContent>
        {currentStep !== -1 && (
          <DialogActions
            sx={{
              justifyContent: 'center',
            }}
          >
            {currentStep < 1 && (
              <Button
                variant='contained'
                color='warning'
                onClick={() => setConfirmationDialogInView(true)}
              >
                No show
              </Button>
            )}
            {currentStep !== 2 ? (
              <Button
                color='primary'
                variant='contained'
                // disabled={currentStep === 0 ? !canAdvanceToNextStep() : false}
                disabled={currentStep === 0 ? !canAdvanceToNextStep : false}
                onClick={handleButtonClick}
              >
                <Typography>
                  {currentStep === 0 ? 'Review' : currentStep === 1 ? 'Generate' : null}
                </Typography>
              </Button>
            ) : null}
            <Button color='error' variant='contained' onClick={handleCancelCloseButtonClick}>
              <Typography>{currentStep !== 2 ? 'Cancel' : 'Close'}</Typography>
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {confirmationDialogInView && (
        <ConfirmDialog
          title='Please confirm this action.'
          message={
            currentStep === 0 ? confirmationData[0].message : confirmationData[currentStep].message
          }
          handleClick={
            currentStep === 0
              ? confirmationData[0].function
              : confirmationData[currentStep].function
          }
        />
      )}
    </>
  );
};

export default PickupBinDialog;
