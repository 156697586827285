import { AccountCircle } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Stack,
  Switch,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { createTodoBatch, editTodo } from '../../../../api/fc-os/todos';
import { FirebaseTodo, OSTodo } from '../../../../api/fc-os/types/todos-types';
import { Employee, ErrorType } from '../../../../types/types';
import { formatYYYMMDD } from '../../../../utils/formatting';
import { validateMinRequirements, validateRegex } from '../../../../utils/validating';
import { MasterItem, SimpleEmployee } from '../../types';
import s from './style.module.scss';

const TodoForm = ({
  user,
  employees,
  item,
  handleCancel,
  resetAfterSuccessfulEdit,
}: {
  // SECTION Data Types
  user: Employee;
  employees: SimpleEmployee[];
  item: MasterItem;
  handleCancel: () => void;
  resetAfterSuccessfulEdit: () => void;
}) => {
  // Input States
  const queryClient = useQueryClient();
  const [editItem, setEditItem] = useState<MasterItem>(item);
  const [owners, setOwners] = useState<{ name: string; uid: string; isSelected: boolean }[]>(
    setupOwners(),
  );
  const [title, setTitle] = useState<string>(editItem ? editItem.title : '');
  const [dueDate, setDueDate] = useState<string>(
    editItem && 'dueDate' in editItem ? formatYYYMMDD((editItem as OSTodo).dueDate.toDate()) : '',
  );
  // const [status, setStatus] = useState<string>(editItem ? editItem.status : '')
  const [description, setDescription] = useState<string>(
    editItem && editItem.description ? editItem.description : '',
  );
  // const [type, setType] = useState<string>('')
  const [isPersonal, setIsPersonal] = useState<boolean>(
    editItem && 'personal' in editItem ? (editItem as OSTodo).personal : false,
  );
  // owners drop down menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // ERROR Handling
  const resetErrorMessage = { isError: false, message: '' };
  const [titleError, setTitleError] = useState<ErrorType>(resetErrorMessage);
  const [dueDateError, setDueDateError] = useState<ErrorType>(resetErrorMessage);
  const [ownerError, setOwnerError] = useState<ErrorType>(resetErrorMessage);
  const ITEM_HEIGHT = 48;

  const ERROR_MESSAGES = {
    title: '3 Characters Minimum',
    dueDate: 'Must be correct format dd/mm/yyyy',
  };

  // needs to be a function to be found
  // this function is useful whem editing. It checks to see what
  // employees have already been assigned this Todo
  function setupOwners() {
    // we need to find the owner of this Todo
    // CAUTION  - If we are in edit mode then we can only have one owner.
    if (editItem) {
      const _editItem: OSTodo = { ...(editItem as OSTodo) };
      const finalOwners: { name: string; uid: string; isSelected: boolean }[] = employees.map(
        (owner) => {
          return { ...owner, isSelected: false };
        },
      );

      const ownerIndex = finalOwners.findIndex((employee) => {
        return employee.uid === _editItem.owner.uid;
      });

      if (ownerIndex > -1) {
        const _owner = { ...finalOwners[ownerIndex] };
        _owner.isSelected = true;
        finalOwners[ownerIndex] = _owner;
      }
      return finalOwners;
    } else {
      return employees.map((owner) => {
        return { ...owner, isSelected: false };
      });
    }
  }

  const resetAllValues = () => {
    setTitle('');
    setDueDate('');
    // setStatus('');
    setDescription('');
    // setType('');
    // editTodo = null
    setEditItem(null);
    setOwners(
      employees.map((employee) => {
        return { ...employee, isSelected: false };
      }),
    );
    setTitleError(resetErrorMessage);
    setDueDateError(resetErrorMessage);
  };
  // NOTE closes modal on Submit that I passed from parent Modal component
  // const handleClose = () => {
  //   insideHandleClose();
  // };
  const handleSubmit = () => {
    // validate the form and check if there are any errors
    const hasErrors = validateForm();
    const errorKeys = Object.keys(hasErrors);
    if (errorKeys.length > 0) {
      // iterate through the keys and set the errors
      errorKeys.forEach((key) => {
        if (key === 'title') {
          setTitleError(hasErrors[key]);
        } else if (key === 'dueDate') {
          setDueDateError(hasErrors[key]);
        } else if (key === 'owner') {
          setOwnerError(hasErrors[key]);
        }
      });
      return;
    }
    // we need to edit
    if (editItem) {
      const _editItem: OSTodo = { ...(editItem as OSTodo) };
      _editItem.owner = {
        name: owners[0].name,
        uid: owners[0].uid,
      };
      _editItem.title = title;
      const _dueDate = dayjs(dueDate).unix();
      _editItem.dueDate = new Timestamp(_dueDate, 0);
      _editItem.description = description;
      // editTodoMutation.mutate({ id: String(editItem.id), _editItem });
      editTodoMutation.mutate(_editItem);
    } else {
      // we are creating a new one
      const userInfo = {
        name: `${user.firstName} ${user.lastName}`,
        uid: user.uid ? user.uid : 'fixUID',
      };
      // TODO: Change the Process for the a Todo that is a Team todo
      // a Team todo should create the same Todo for each owner
      // const finalizedOwners: { name: string, uid: string }[] = []
      const allTodos: FirebaseTodo[] = [];
      const dueDateSeconds = dayjs(dueDate).unix();
      owners.forEach((owner) => {
        // if (owner.isSelected) finalizedOwners.push({ name: owner.name, uid: owner.uid })
        if (owner.isSelected) {
          const newTodo: FirebaseTodo = {
            comments: null,
            createdAt: Timestamp.now(),
            createdBy: userInfo,
            description,
            dueDate: new Timestamp(dueDateSeconds, 0),
            isDeleted: false,
            owner: {
              uid: owner.uid,
              name: owner.name,
            },
            personal: isPersonal,
            status: 'incomplete',
            storeUID: user.storeID,
            team: {
              name: 'Fresh Clothes',
              uid: 'noUID',
            },
            title,
            updateHistory: null,
            updatedAt: Timestamp.now(),
          };
          allTodos.push(newTodo);
        }
      });
      addNewTodoMutation.mutate(allTodos);
    }
  };
  // This funciton will check the title and due date
  // it will then return the Errors
  const validateForm = () => {
    const errors: { [key: string]: ErrorType } = {};
    // checking title
    const _title = title.trim();
    if (_title.length < 3) {
      errors['title'] = { isError: true, message: 'Must be greater than 3 and not empty.' };
    }
    // validating the Due Date
    if (dueDate.length < 1) {
      errors['dueDate'] = { isError: true, message: 'Must have a due date' };
    }
    // checking that this todo has an owner
    let hasAtLeaseOneOwner = false;
    owners.forEach((owner) => {
      if (owner.isSelected) {
        hasAtLeaseOneOwner = true;
      }
    });
    if (!hasAtLeaseOneOwner) {
      errors['owner'] = { isError: true, message: 'Must have at least one owner' };
    }
    return errors;
  };

  // NOTE Had to change dates for the date form. I followed Miguel's Customers edit has a reference
  const handleDateChange = (newDate: string) => {
    setDueDate(newDate);
    if (!validateRegex(newDate, /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/))
      setDueDateError({ isError: true, message: ERROR_MESSAGES.dueDate });
    else setDueDateError(resetErrorMessage);
  };

  // NOTE changed Team/Personal complete/imcomplete and Rock On-Track/ Done
  // const handleStatus = (newStatus: string) => {
  //   setStatus(newStatus);
  // }

  const handleOwnersClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // This function handles assigning Owners to a Todo.
  // If it is a NEW Todo then all avatars can be selected
  // If it is an EDIT Todo then only ONE avatar can be selected
  const handleOwnerAvatarClick = (
    user: { name: string; uid: string; isSelected: boolean },
    index: number,
  ) => {
    if (editItem) {
      // copy the lsit of employees
      const finalOwners: { name: string; uid: string; isSelected: boolean }[] = employees.map(
        (owner) => {
          return { ...owner, isSelected: false };
        },
      );
      // find the new selected owner
      const ownerIndex = finalOwners.findIndex((employee) => {
        return employee.uid === user.uid;
      });
      // make that owner the only selected one
      if (ownerIndex > -1) {
        const _owner = { ...finalOwners[ownerIndex] };
        _owner.isSelected = true;
        finalOwners[ownerIndex] = _owner;
      }
      setOwners(finalOwners);
    } else {
      const _user = { ...user };
      _user.isSelected = !_user.isSelected;
      const _owners = [...owners];
      _owners[index] = _user;
      setOwners(_owners);
    }
  };

  // mutation for adding the newly created Todo
  const addNewTodoMutation = useMutation({
    mutationFn: (finalizedItems: FirebaseTodo[]) => createTodoBatch(finalizedItems),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['todos'] });
      const previousTodos = queryClient.getQueryData<OSTodo[]>(['todos']);
      if (previousTodos) {
        // only add a todo if..
        // it is a team Todo OR
        // if (!finalizedItem.personal) {
        //   queryClient.setQueryData<OSTodo[]>(
        //     ['todos'],
        //     [...previousTodos, { id: '', ...finalizedItem }],
        //   );
        // }
        // else {
        // it is a personal Todo assigned to the current user
        // check to see if the owners of this Todo include user
        // const isAnOwnerOfTodo = finalizedItem.owners.findIndex((owner) => {
        //   return owner.uid === user.uid
        // })
        // if (isAnOwnerOfTodo > -1) {
        //   console.log('they are an owner of this personal todo')
        //   queryClient.setQueryData<OSTodo[]>(
        //     ['todos'],
        //     [...previousTodos, { id: '', ...finalizedItem }],
        //   );
        // }
        // }
      }
      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    onSuccess: (data, variables, context) => {
      if (context && context.previousTodos && data) {
        // data is what is returned from the API
        // iterate through the list of recent Todo's created and add to the appropriate card
        const todosToAdd: OSTodo[] = [];
        data.forEach((td) => {
          // if item is a Team todo then add to the list
          if (!td.personal || td.owner.uid === user.uid) {
            todosToAdd.push(td);
          }
        });
        //   const finalizedItem: OSTodo = { id: data.id, ...variables };
        // Replace optimistic todo in the todos list with the result
        queryClient.setQueryData<OSTodo[]>(['todos'], [...context.previousTodos, ...todosToAdd]);
        toast.success('To do created!');
        resetAllValues();
        handleCancel();
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, finalizedItem, context) => {
      if (context?.previousTodos) {
        queryClient.setQueryData(['todos'], context.previousTodos);
        toast.error('Failed to create new todo');
      }
    },
  });
  // mutation for editing an existing Todo
  const editTodoMutation = useMutation({
    mutationFn: (finalizedItem) => editTodo(finalizedItem),
    onMutate: async (finalizedItem: OSTodo) => {
      await queryClient.cancelQueries({ queryKey: ['todos'] });
      const previousTodos = queryClient.getQueryData<OSTodo[]>(['todos']);
      if (previousTodos) {
        // TODO: on edit we need to check to see if the User is still the owner of the
        // edited Todo
        // we have to find the current item and replace it
        const foundTodoIndex = previousTodos.findIndex((todo) => {
          return todo.id === finalizedItem.id;
        });
        if (foundTodoIndex > -1) {
          previousTodos[foundTodoIndex] = finalizedItem;
          queryClient.setQueryData<OSTodo[]>(['todos'], [...previousTodos]);
        }
      }
      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    onSuccess: (data, variables, context) => {
      if (context && context.previousTodos) {
        queryClient.setQueryData<OSTodo[]>(['todos'], [...context.previousTodos]);
        // const finalizedItem: OSTodo = { id: data.id, ...variables };
        // Replace optimistic todo in the todos list with the result
        toast.success('Successfully updated todo!');
        resetAllValues();
        handleCancel();
        resetAfterSuccessfulEdit();
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, finalizedItem, context) => {
      if (context?.previousTodos) {
        queryClient.setQueryData(['todos'], context.previousTodos);
        toast.error('Failed to create new todo');
      }
    },
  });

  return (
    <>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            width={'80%'}
            height={'100%'}
          >
            <Box
              width={'10%'}
              height={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <IconButton
                onClick={handleOwnersClick}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <AccountCircle sx={{ color: ownerError.isError ? 'red' : 'action.active' }} />
              </IconButton>
            </Box>
            <Menu
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                },
              }}
            >
              {owners.map((user, index) => {
                return (
                  <MenuItem
                    key={`user-${index}`}
                    value={user.name}
                    onClick={() => handleOwnerAvatarClick(user, index)}
                  >
                    <Stack direction={'row'} height={'3vh'} spacing={1}>
                      <Checkbox checked={user.isSelected} color={'primary'} />
                      <Avatar
                        src={'https://i.pravatar.cc/?img=' + user.name}
                        sx={{ width: 25, height: 25 }}
                      ></Avatar>
                      <Typography>{user.name}</Typography>
                    </Stack>
                  </MenuItem>
                );
              })}
            </Menu>
            <Box width={'90%'}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: '98%' }}
                required
                label='Name the To-Do'
                variant='standard'
                error={titleError.isError}
                helperText={titleError.message}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (!validateMinRequirements(e.target.value, 3))
                    setTitleError({ isError: true, message: ERROR_MESSAGES.title });
                  else setTitleError(resetErrorMessage);
                }}
              ></TextField>
            </Box>
          </Box>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mt: 2 }}
            required
            label='Due Date'
            variant='standard'
            type={'date'}
            value={dueDate}
            error={dueDateError.isError}
            helperText={dueDateError.message}
            onChange={(e) => handleDateChange(e.target.value)}
          ></TextField>
        </Box>
        {ownerError.isError && (
          <Box>
            <Typography color={'red'} fontSize={8}>
              {ownerError.message}
            </Typography>
          </Box>
        )}
        <Box mt={2}>
          <TextareaAutosize
            placeholder='Description'
            minRows={5}
            className={s.textWidth}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></TextareaAutosize>
        </Box>
        {/* SECTION Todo Status input only on Edit */}
        {/* {editTodo != null ? 
      <Box>
        <FormControl variant="standard" sx={{ m: 1, minWidth: '95%' }}>
          <InputLabel shrink={true} required id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status-select-standard"
            value={status}
            label="Status"
          >
            {statuses.map((status) => {
              return (
                <MenuItem key={status} value={status} onClick={() => handleStatus(status)}>
                  {status}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box> : ''} */}
        <Box mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <InputLabel shrink={true}>Team</InputLabel>
            <Typography>Fresh Clothes Leadership</Typography>
          </Box>
          <Box>
            <FormControlLabel
              label='Personal'
              control={
                <Switch
                  value={isPersonal}
                  checked={isPersonal}
                  onChange={() => setIsPersonal(!isPersonal)}
                />
              }
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color='inherit'
          sx={{ px: 2, mx: 2 }}
          variant='contained'
          onClick={() => {
            resetAllValues();
            resetAfterSuccessfulEdit();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            title.length < 1 || titleError.isError || dueDate.length < 1 || dueDateError.isError
          }
          fullWidth
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          {editItem ? 'Edit' : 'Create'}
        </Button>
      </DialogActions>
    </>
  );
};

export default TodoForm;
