/**
 * This component will handle creating the QR codes and printing
 * NOTE: A QR code is simply a URL converted into a scanable image
 */
import { Box, Divider, Typography } from '@mui/material';
import QRCode from 'qrcode';
import { useQuery } from 'react-query';
import { createPickupHistory } from '../../../api/laundry-pickup/pickup-history';
import LoadingSpinner from '../../common/LoadingSpinner';
import { BasicPickupBag, BasicPickupBagWithQRCode, PickupHistory } from '../types';
import PrintableBagTag from './PrintableBagTag';

type Props = {
  pickupBins: PickupHistory[];
  washType: string;
};

const PickupBinQrCodes = ({ pickupBins, washType }: Props) => {
  const { isError, data } = useQuery(['pickupHistory'], () => createPickupHistory(pickupBins));

  const generateQRCodes = (pickupHistory: BasicPickupBag[]) => {
    const finalQRCodesList: BasicPickupBagWithQRCode[] = [];
    for (let i = 0; i < pickupHistory.length; i++) {
      QRCode.toDataURL(
        `https://admin.freshclothes.app/#/bin-history/${pickupHistory[i].uid}`,
        {
          margin: 1,
        },
        (err, qr) => {
          if (err) return console.error(err);
          finalQRCodesList.push({ ...pickupHistory[i], qrCodeURL: qr });
        },
      );
    }
    return finalQRCodesList;
  };

  if (isError) {
    return (
      <Box>
        <Typography>Error creating</Typography>
      </Box>
    );
  }
  if (data) {
    return (
      <Box>
        {generateQRCodes(data).map((qr) => {
          return (
            <>
              <Divider />
              <Box key={qr.bagNumber} mt={1}>
                <PrintableBagTag tagData={qr} washType={washType} />
              </Box>
            </>
          );
        })}
      </Box>
    );
  }
  return (
    <Box>
      <LoadingSpinner />
    </Box>
  );
};

export default PickupBinQrCodes;
